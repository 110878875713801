import { fireViewEndCustomEvent } from './adobeAnalyticsEvents';
import { adobeLaunchURL, payload } from './config';
import { digitaldata } from './config';
import $ from 'jquery';

declare const _satellite: any;

export const setPageInDigitalData = (dataLayerObj: any) => {
  digitaldata['page'] = {
    ...digitaldata['page'],
    ...dataLayerObj['page']
  };
};

export const setPageNameObj = (dataLayerObj: any, valToSet: string) => {
  dataLayerObj['page']['pageName'] = valToSet;
  return dataLayerObj;
};

// attach analytics script to head tag of SPA
const createAnalyticsScript = () => {
  let scriptLaunchTag = document.createElement('script');
  scriptLaunchTag.type = 'text/javascript';
  scriptLaunchTag.src = adobeLaunchURL;
  scriptLaunchTag.async = true;
  scriptLaunchTag.setAttribute('id', 'scriptLaunchTag');
  document.getElementsByTagName('head')[0].appendChild(scriptLaunchTag);
};

// edit digital data based on data received from component
const mutateDigitaldata = (dataLayerObjStringified: string) => {
  let dataLayerObj = JSON.parse(dataLayerObjStringified);
  dataLayerObj = setPageNameObj(dataLayerObj, dataLayerObj['page']['pageName']);
  setPageInDigitalData(dataLayerObj);
  // fireViewEndCustomEvent();
};

export const executeAnalytics = (dataLayerObj: object) => {
  const dataLayerObjStringified = JSON.stringify(dataLayerObj);
  mutateDigitaldata(dataLayerObjStringified);

  const elementExists = document.getElementById('scriptLaunchTag');
  if (!elementExists) {
    createAnalyticsScript();
  }
  fireViewEndCustomEvent();
};

// removes query parameters - use this if the project has hash router

export const getLastUri = (url_param: string) => {
  let url = new URL(decodeURIComponent(url_param));
  url.search = '';
  const processed_Url = url.toString().split('/');
  let lastUri = processed_Url[processed_Url.length - 1];
  let processedUri = lastUri.indexOf('#') != -1 ? lastUri.split('#')[0] : lastUri;
  if (processedUri == '') {
    return 'Home';
  }
  return processedUri;
};

//#region Referral Tracking
export const getUrlParameter = (sParam: string) => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam && sParameterName[0] === 'cid') {
      //return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      return decodeURIComponent(sParameterName[1]);
    }
  }
};

//analytics tracking call definition
export const referrerTrack = (action: string) => {
  if (typeof action !== 'undefined') {
    payload.eventProperties.category = 'Referrer';
    payload.action = action;
    _satellite.track('eventTrack', payload);
    payload.action = '';
    payload.eventProperties.category = '';
  }
};

export const getParamValue = (param: string) => {
  var sPageURL = window.location.search.substring(1);
  var paramDelimiter = ['&', '/', '?', '#'];
  if (sPageURL.indexOf(param + '=') != -1) {
    var sParamAfterSplit = sPageURL.split(param + '=')[1];
    for (var x = 0; x < sParamAfterSplit.length; x++) {
      if (paramDelimiter.includes(sParamAfterSplit.charAt(x))) {
        return sParamAfterSplit.substring(0, x);
      }
    }
    return sParamAfterSplit;
  }
};

export const removeURLParameter = (pageUrl: string, parameter: string, paramValue: string) => {
  return pageUrl.replace(parameter + '=' + paramValue, '').replace('?', '');
};

export const referrerTracking = () => {
  const paramValue = getParamValue('cid');
  var pageUrl = window.location.href;
  if (typeof paramValue !== 'undefined') {
    referrerTrack(paramValue);
    const paramRemovedURL = removeURLParameter(pageUrl, 'cid', paramValue);
    window.history.pushState({}, '', paramRemovedURL);
  }
};

setTimeout(function () {
  referrerTracking();
}, 500);
//#endregion Referral Tracking

////// Generic link click Tracking ////////
const linkClickTracking = (linkName: string) => {
  if (typeof _satellite !== 'undefined') {
    digitaldata.onclick.linkName = linkName;
    _satellite.track('Link Tracking');
    digitaldata.onclick.linkName = '';
  }
};

$(document).on('mousedown', 'a', (e: { currentTarget: any }) => {
  let linkLabel = $(e.currentTarget).text();
  if (linkLabel !== '') {
    linkClickTracking(linkLabel);
  }
});
