import {
  EMBRYONIC_APP_URL,
  GENERATE_APP_URL,
  PEVC_APP_URL,
  RESPONSE_STATUS_CODE,
  SECONDARY_RESEARCH_APP_URL,
  SUSTAINABILITY_APP_URL,
  VALUE_ACCELERATOR_APP_URL
} from '../app.constant';
import { IE_APPS, LOCALSTORAGE_PROJECT_DATA, publicSources, USER_TYPE } from '../label.constant';
import { DataSourceTypes } from '../models/IDataSourceTypes';
import { RESPONSE_BY } from '../models/IQueryType';
import { authProvider } from '../services/msalConfig';
import { Guid } from 'guid-typescript';
import {
  getCurrentSessionId,
  initializeSession,
  setCurrentSessionId
} from '../services/session.service';
import {
  deleteChatThreadHistory,
  deleteDocThreadHistory,
  removeThreadFromCache
} from '../services/chatQuery.service';
import {
  saveUserChatThreadHistory,
  saveUserDocumentThreadHistory,
  setSelectedChatThread,
  setSelectedDocumentThread,
  setLastSavedThreadID
} from '../store/actions/userChatActions';
import chatApiRoutes from '../services/api';
import { HttpStatusCode } from '../ui-constants/base/enum';
import LABELS from '../ui-constants/en.json';
import { getTeamsAuthToken } from '../services/api.service';
import { jwtDecode } from 'jwt-decode';
import { setUSerEmail } from '../store/actions/commonActions';
import { setUserSharePointTokenForTeamsApp, storeTeamsUserProfile } from '../store/actions/userProfileActions';
import { useDispatch, useSelector } from 'react-redux';
import { getSharePointTokenForTeams } from '../services/teamsProject.service';
import { IStore } from '../store';
import CryptoJS from 'crypto-js';

/**
 * Returns the URL of the app based on the app name
 * @param appName
 * @returns
 */
export const getAppURL = (appName: string) => {
  switch (appName) {
    case IE_APPS.EMBRYONIC:
      return EMBRYONIC_APP_URL;
    case IE_APPS.PEVC:
      return PEVC_APP_URL;
    case IE_APPS.SUSTAINABILITY:
      return SUSTAINABILITY_APP_URL;
    case IE_APPS.GENERATE:
      return GENERATE_APP_URL;
    case IE_APPS.OUTSIDE_WEB_DATA:
      return SECONDARY_RESEARCH_APP_URL;
    case IE_APPS.BENCHMARKS:
      return '';
    case IE_APPS.VA:
      return VALUE_ACCELERATOR_APP_URL;
    default:
      return EMBRYONIC_APP_URL;
  }
};
export const sanitizeHeaderText = (header: string) => {
  return header.replace('company_', '').replace('_', ' ');
};
/**
 * Opens the link in a new tab with the user's email ID first time and if it is already open, it navigates to the same tab
 * @param item
 * @param userMailID
 */
export const openLinkInSameTab = (item: any, userMailID: string) => {
  const itemName = item?.heading || item?.name;
  if (item?.url) {
    const url =
      itemName === IE_APPS.EMBRYONIC
        ? isTeamsApp() ? encodeURI(`${item?.url}landing-page?q={"authInfo":"${userMailID}"}&&source=teams`) : encodeURI(`${item?.url}landing-page?q={"authInfo":"${userMailID}"}`)
        : isTeamsApp() ? encodeURI(`${item?.url}?source=teams`) : encodeURI(`${item?.url}`);

    // Check if a window with the same name is already open
    const existingWindow = window.open('', `${itemName}_myWindowName`);

    if (existingWindow) {
      // If a window with the same name is open, navigate it to the new URL
      existingWindow.location.href = url;
    } else {
      // If no window with the same name is open, open a new window
      window.open(url, `${itemName}_myWindowName`);
    }
  }
};

export function getFileExtension(fileName: any) {
  var re = /(?:\.([^.]+))?$/;
  const match = re.exec(fileName);
  return match && match[1] != null ? match[1] : '';
}
export const isValidURL = (urlString: string) => {
  const urlRegex =
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3})|(\[[0-9a-f:]+\]))(:\d+)?(\/[-a-z\d%_.~+:@]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
  return urlRegex.test(urlString);
};

export const toPascalCase = (str: string) => {
  return str.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};
export const checkIsJson = (str: string) => {
  const rx =
    /^[\],:{}\s]*$|\/(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})*$|"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?$/;
  const value = rx.test(str);
  return value;
};
export const downloadFilenameGenerator = (
  from: RESPONSE_BY | 'ALL',
  extension: 'ppt' | 'docx' | 'csv'
) => {
  const guid = Guid.create();
  const source =
    extension === 'docx'
      ? 'SI GPT'
      : from === RESPONSE_BY.OPENAI
        ? 'OpenAI'
        : from === RESPONSE_BY.STRATEGY_EDGE
          ? 'SI GPT'
          : 'User';

  const fileName = `${source}-${
    extension === 'docx' ? 'conversation' : 'chat reply'
  }-${guid}.${extension}`;
  return fileName;
};

export const GenrateRandomAuthor = () => {
  const random = Math.floor(Math.random() * 10);
  const Anshul = [
    {
      name: 'Anshul Gupta',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Canshul.gupta%40uk.ey.com'
    }
  ];
  const Priyam = [
    {
      name: 'Priyam Lahiri',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Cpriyam.lahiri%40uk.ey.com'
    }
  ];

  const multipleAuthors = [
    {
      name: 'Priyam Lahiri',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Cpriyam.lahiri%40uk.ey.com'
    },
    {
      name: 'Anshul Gupta',
      profile:
        'https://people.ey.com/PersonImmersive.aspx?accountname=i:0%23.f%7Cmembership%7Canshul.gupta%40uk.ey.com'
    }
  ];
  if (random % 3 === 0) {
    return Anshul;
  }
  if (random % 3 === 1) {
    return Priyam;
  } else {
    return multipleAuthors;
  }
};

export const checkOnySpecialCharacter = (str: string) => {
  const rx = /^[^a-zA-Z]+$/;
  var res = rx.test(str);
  return res;
};

export const checkOnlySpaces = (str: string) => {
  const rx = /^\s+$/;
  var res = rx.test(str);
  return res;
};
export const getAuthorizationToken = async () => {
  if (isTeamsApp()) {
    const teamsResponse: any = await getTeamsAuthToken();
    return teamsResponse.token;
  }
  const res = await authProvider?.getIdToken();
  return res.idToken.rawIdToken;
};

export const getSourceTypeName = (sourceType: string) => {
  switch (sourceType) {
    case 'public':
      return 'External Data';
    case 'internal':
      return 'EY Source Data';
    case 'uploadDocument':
      return 'Upload Document and Query';
    case 'uploadUrl':
      return 'Upload URL and query';
    case 'findEyExpert':
      return 'Find EY Expert';
    default:
      return 'Public';
  }
};

/**
 * Set all threads in the chat history to unselected
 * @param threads
 */
export const setThreadsUnSelected = (threads: any) => {
  threads?.forEach((thread: any) => {
    thread.isSelected = false;
  });
};

/**
 * Return the current data source value using enum based on the selected data source
 * @param checkedCheckboxes
 * @param eyIPInterRadio
 * @param validURLinputValue
 * @returns
 */
export const getCurrentDataSource = (
  checkedCheckboxes: any,
  eyIPInterRadio: any,
  validURLinputValue: any,
  selectedDataSourceSection?: number
) => {
  if (checkedCheckboxes?.length > 0) return DataSourceTypes.PublicData;
  else if (eyIPInterRadio && eyIPInterRadio.length > 0) return DataSourceTypes.EYIP;
  else if (validURLinputValue && validURLinputValue.length > 0) return DataSourceTypes.UploadURL;
  else if (selectedDataSourceSection === 5) return DataSourceTypes.Experts;
  else return DataSourceTypes.BYOD;
};

/**
 * Return the current sub data source value(s) as text based on the selected data source
 * @param checkedCheckboxes
 * @param eyIPInterRadio
 * @param validURLinputValue
 * @returns
 */
export const getCurrentSubDataSource = (
  checkedCheckboxes: any,
  eyIPInterRadio: any,
  validURLinputValue: any
) => {
  if (checkedCheckboxes?.length > 0) return checkedCheckboxes.join(',');
  else if (eyIPInterRadio && eyIPInterRadio.length > 0) return eyIPInterRadio;
  else if (validURLinputValue && validURLinputValue.length > 0) return validURLinputValue;
  else return 'Expert';
};

export const getComputedRequestObject = (
  selectedChatThread: any,
  regeneratedMessageId: number,
  chatDataSummmary: any,
  checkedCheckboxes: any,
  eyIPInterRadio: string | undefined,
  validURLinputValue: string | undefined,
  firstResponseTime?: number,
  totalResponseTime?: number | null,
  isEYIPData?: boolean,
  isCompletelyAbortedResposne?: boolean,
  isParitallyAbortedResponse?: boolean,
  selectedDataSourceSection?: number
) => {
  let targetUserSummary = isEYIPData
    ? chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 2].user?.summary
    : chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1].user?.summary;

  let tempSEResponse = {
    ...chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1].SEResponse
  };
  tempSEResponse.isCompletelyAbortedResposne = isCompletelyAbortedResposne;
  tempSEResponse.isParitallyAbortedResponse = isParitallyAbortedResponse;
  let targetSEResponseSummary = JSON.stringify(tempSEResponse);

  return {
    threadId: selectedChatThread?.threadId || 0,
    messageId: regeneratedMessageId || 0,
    title: targetUserSummary,
    dataSource: getCurrentDataSource(
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue,
      selectedDataSourceSection
    ),
    dataSubSource: getCurrentSubDataSource(checkedCheckboxes, eyIPInterRadio, validURLinputValue),
    sessionId: selectedChatThread?.sessionId || getCurrentSessionId(),
    question: targetUserSummary,
    response: targetSEResponseSummary,
    firstResponseTime: firstResponseTime?.toFixed(2),
    totalResponseTime: totalResponseTime?.toFixed(2)
  };
};

/**
 * Save user chat thread history and delete the oldest thread if the history length is more than 10
 * @param reqObj
 * @param savedNewThreadId
 * @param userChatThreads
 * @param dispatch
 */
export const handleUserChatThreadHistory = (
  reqObj: any,
  savedNewThreadId: any,
  userChatThreads: any,
  userData: any,
  dispatch: any
) => {
  let savedChatThread: any = {};
  savedChatThread = {
    threadId: savedNewThreadId,
    sessionId: reqObj.sessionId,
    title: reqObj.title,
    isEditEnabled: false,
    isSelected: true,
    dataSource: reqObj.dataSource,
    dataSubSource: reqObj.dataSubSource
  };
  let updatedChatThreads = [savedChatThread, ...userChatThreads];
  let chatThreadsToBeDeleted = updatedChatThreads.slice(10, updatedChatThreads.length);
  if (chatThreadsToBeDeleted?.length > 0) {
    const currentThread = chatThreadsToBeDeleted[0];
    setCurrentSessionId(reqObj.sessionId);
    deleteChatThreadHistory(currentThread?.threadId);
    const targetAPIURL = getCacheRemoveAPIURL(currentThread);
    if (targetAPIURL) removeThreadFromCache(targetAPIURL, currentThread.sessionId);
  }
  dispatch(setSelectedChatThread(savedChatThread));
  dispatch(saveUserChatThreadHistory(updatedChatThreads.slice(0, 10)));
  dispatch(setLastSavedThreadID(savedNewThreadId));
};

export const handleDocumentThreadHistory = (
  ProjectName: any,
  savedNewThreadId: any,
  documentThreads: any,
  dispatch: any
) => {
  let savedDocThread: any = {};
  savedDocThread = {
    threadId: savedNewThreadId,
    title: ProjectName,
    isEditEnabled: false,
    isSelected: true,
    dataSource: DataSourceTypes.BYOD
  };
  let updatedDocThreads = [savedDocThread, ...documentThreads];
  let docThreadsToBeDeleted = updatedDocThreads.slice(10, updatedDocThreads.length);
  if (docThreadsToBeDeleted?.length > 0) {
    deleteDocThreadHistory(docThreadsToBeDeleted[0]?.threadId);
  }
  dispatch(setSelectedDocumentThread(savedDocThread));
  dispatch(saveUserDocumentThreadHistory(updatedDocThreads.slice(0, 10)));
};

export const getResponseSourceType = (type?: string, url?: any) => {
  switch (type) {
    case 'EY-P Strategy projects':
      return 'EY-P Strategy projects';
    case 'Surveys':
      return 'EY conducted surveys';
    case 'Discover: Sector Insights':
      return 'Discover: Sector Insights';
    case 'Open AI':
      return 'Secondary research';
    case 'Upload URL':
      return `${url}`;
    case 'Combined':
      return 'Embryonic, Secondary research';
    case 'Experts':
      return 'Discover Knowledge Capture';
    default:
      return 'Embryonic';
  }
};

export const IsJSONResponse = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getCacheRemoveAPIURL = (thread: any) => {
  let computedAPIURL = '';
  if (
    thread?.dataSource === DataSourceTypes.PublicData ||
    thread?.dataSource === DataSourceTypes.UploadURL
  )
    computedAPIURL = chatApiRoutes.clearEgptChat;
  else if (thread?.dataSource === DataSourceTypes.EYIP) {
    if (thread?.dataSubSource === 'EY-P Strategy projects')
      computedAPIURL = chatApiRoutes.clearKcUserSession;
    else if (thread?.dataSubSource === 'Surveys')
      computedAPIURL = chatApiRoutes.clearSUIUserSession;
    else if (thread?.dataSubSource === 'Discover: Sector Insights')
      computedAPIURL = chatApiRoutes.clearSIUserSession;
  }
  return computedAPIURL;
};

export const handleSessionID = (selectedChatThread: any) => {
  if (selectedChatThread && selectedChatThread?.sessionId) {
    setCurrentSessionId(selectedChatThread?.sessionId);
  } else {
    initializeSession(true);
  }
};

export const truncateString = (str = '', maxLength?: number): string => {
  if (str) {
    maxLength = maxLength ? maxLength : 250;
    if (str?.length > maxLength) {
      const truncatedText = str.substring(0, maxLength).replace(/([^a-zA-Z]+)$/, '');
      return `${truncatedText}...`;
    }
    return str;
  }
  return '';
};
export const getXMonthsDate = (date: Date, months: number) => {
  date.setMonth(date.getMonth() - (months - 1));
  return date;
};

export const handleAvatarInitials = (
  eyIPInterRadio: string = '',
  checkedCheckboxes: string[] = [],
  selectedDataSourceSection?: number
) => {
  let source =
    eyIPInterRadio === ''
      ? checkedCheckboxes?.length > 1
        ? 'Combined'
        : checkedCheckboxes[0]
      : eyIPInterRadio;
  let avatarInitial: string = '';
  if (source === 'Embryonic') {
    avatarInitial = 'E ';
  } else if (source === 'Open AI') {
    avatarInitial = 'Artifical Intelligence';
  } else if (source === 'Surveys') {
    avatarInitial = 'S U';
  } else if (source === 'Discover: Sector Insights') {
    avatarInitial = 'Sector Insights';
  } else if (selectedDataSourceSection === 5) {
    avatarInitial = 'Knowledge Capture';
  } else {
    avatarInitial = 'Competitive Edge';
  }
  return avatarInitial;
};

export const getErrorLabel = (statusCode: number, checkedCheckboxes: any) => {
  if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[0])) 
  {
    if (statusCode === HttpStatusCode.InternalServerError)
      return LABELS.chat_reply.NETWOR_ERROR_MSG;
    else 
      if (statusCode === HttpStatusCode.NotAcceptable)
      {
        return LABELS.chat_reply.NOT_ACCEPTABLE_ERROR;
      }
      if (statusCode === HttpStatusCode.TimedOut)
        {
          return LABELS.chat_reply.SE_TOKEN_ERROR_MSG;
        }
      else
      {
        return LABELS.chat_reply.EMBROYNIC_ERROR;
      }
  } 
  else 
  {
    if (statusCode === HttpStatusCode.InternalServerError)
      return LABELS.chat_reply.NETWOR_ERROR_MSG;
    else 
      return LABELS.chat_reply.OPENAI_ERROR_MSG;
  }
};

/**
 * Decode the encoded html string to plain text
 * @param html
 * @returns plain text
 */
export const decodeHTML = (html: string): string => {
  const parser = new DOMParser();

  const decode = (input: string): string => {
    const doc = parser.parseFromString(input, 'text/html');
    const text = doc.body.textContent || '';

    // Check if the output is different from the input,
    // indicating that there was some HTML to parse and remove.
    // If so, call the decode function recursively.
    if (text !== input) {
      return decode(text);
    } else {
      // If the output is the same as the input, return the result,
      // as there is nothing left to decode.
      return text;
    }
  };

  return decode(html);
};

export const isTeamsApp = () => {
  return document.referrer.includes('teams.microsoft.com');
};

export const getTeamsSilentToken = async () => {
  const dispatchHandler = useDispatch();
  const teamsTokenObject: any = await getTeamsAuthToken();
  if (teamsTokenObject.token) {
    const token = teamsTokenObject.token;
    const userObject: any = jwtDecode(token);
    dispatchHandler(setUSerEmail(userObject?.preferred_username));
    dispatchHandler(storeTeamsUserProfile({ ...userObject, rawIdToken: token }));
  }
};

export const routeToLogin = async () => {
  if (isTeamsApp()) {
    getTeamsSilentToken();
  } else {
    localStorage.clear();
    const currentUrl =
      window.location.pathname === 'error' ? window.location.origin : window.location.href;
    window.location.href =
      'https://login.microsoftonline.com/common/oauth2/logout?client_id=' +
      authProvider.getCurrentConfiguration().auth.clientId +
      '&post_logout_redirect_uri=' +
      encodeURIComponent(currentUrl);
  }
};

export const getSharePointTeamsToken = async () => {
  const existingUserSharePointTokenForTeamsApp = localStorage.getItem('sharePointTeamsToken') ||
    '';
  if (isTeamsApp()) {
    if (!existingUserSharePointTokenForTeamsApp || isTokenExpired(existingUserSharePointTokenForTeamsApp)) {
      const sharePointForTeamsResponse: any = await getSharePointTokenForTeams();
      // const dispatchHandler = useDispatch();
      // if (sharePointForTeamsResponse.status === RESPONSE_STATUS_CODE.OK) {
      //   dispatchHandler(setUserSharePointTokenForTeamsApp(sharePointForTeamsResponse));
      // }
      localStorage.setItem('sharePointTeamsToken', sharePointForTeamsResponse?.data);
      return sharePointForTeamsResponse?.data;
    } else {
      return existingUserSharePointTokenForTeamsApp;
    }
  }
  const res = await authProvider?.getIdToken();
  return res.idToken.rawIdToken;
};

const isTokenExpired = (token:string) => {
  if (!token) return true;
  try {
    const decodedToken:any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
}

export const getCurrentTimeSlotIndex = (date: Date): number => {
  const minutes = date.getMinutes();
  const hours = date.getHours();

  const totalMinutes = hours * 60 + minutes;
  const interval = 15; // 15 minutes gap

  return Math.floor(totalMinutes / interval);
};

export const clearCreateProjectStorageValues = () => {
  localStorage.removeItem('projectName');
  localStorage.removeItem('milestones');
  localStorage.removeItem(LOCALSTORAGE_PROJECT_DATA);
  localStorage.removeItem(USER_TYPE.CO_OWNER);
  localStorage.removeItem(USER_TYPE.MEMBER);
  localStorage.removeItem(USER_TYPE.ENGAGEMENT_PARTNER);
  localStorage.removeItem(USER_TYPE.ENGAGEMENT_MANAGER);
  localStorage.removeItem('selectedTemplate');
  localStorage.removeItem('selectedTemplateID');
  localStorage.removeItem('templateName');
};


export const getFirstFolderName = (path: string) => {
  if (!path) {
    return null;
  }
  const parts = path?.split('/');
  for (const part of parts) {
    if (part.trim() !== '') {
      return part;
    }
  }
  return null;
}
/**
 * Returns the file size from bytes
 * @param bytes 
 * @returns 
 */
export const formatFileSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};
/**
 * Returns true if the string contains special characters
 * @param name 
 * @returns 
 */
export const isContainSpecialCharacters = (name:string) => {
  // var regex = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
  var regex = /[`!@#$%^*+\=\[\]{};':"\\|,<>\/?~]/;
   return regex.test(name);
 }

 /**
  * Convert an array of headers and values to a plain text table
  * @param array Contains headers and values
  * @returns 
  */
 export const convertArrayToPlainTextTable = (array:any, enableTableBorder = true)=> {
  const columnLengths = array.headers.map((header:string, index:number) => {
    // Start with the length of the header
    let maxLength = header.length;
    // Compare with the length of each value in the column
    array.values.forEach((row:any) => {
      const valueLength = row[index].val.length;
      if (valueLength > maxLength) {
        maxLength = valueLength;
      }     
    });
    return maxLength;
  });

  let headers, separatorRow, rows;
  if (enableTableBorder) {
    // Create the header row with padding
    headers =
      '| ' +
      array.headers
        .map((header: string, index: number) =>
          toPascalCase(sanitizeHeaderText(header)).padEnd(columnLengths[index], ' ')
        )
        .join(' | ') +
      ' |';

    // Create the separator row
    separatorRow = '| ' + columnLengths.map((length: any) => '-'.repeat(length)).join(' | ') + ' |';

    // Map each row of values to a string with padding
    rows = array.values.map(
      (row: any) =>
        '| ' +
        row
          .map((value: any, index: number) => value.val.padEnd(columnLengths[index], ' '))
          .join(' | ') +
        ' |'
    );
  } else {
    // Create the header row with padding
    headers = array.headers
      .map((header: string, index: number) =>
        toPascalCase(sanitizeHeaderText(header)).padEnd(columnLengths[index], ' ')
      )
      .join(' | ');

    // Create the separator row
    separatorRow = columnLengths.map((length: any) => '-'.repeat(length)).join('-|-');

    // Map each row of values to a string with padding
    rows = array.values.map((row: any) =>
      row
        .map((value: any, index: number) => value.val.padEnd(columnLengths[index], ' '))
        .join(' | ')
    );
  }

  

  // Combine headers, separator, and rows into a single string
  const table = [separatorRow, headers, separatorRow, ...rows].join('\n');

  return table;
}

export const convertRootObjectsToTable = (rootObjects: any[]): string => {
  const headers = ['Name', 'Role', 'Type', 'End date', 'Geography'];
  const columnLengths = headers.map(header => header.length);

  const rows = rootObjects.flatMap(rootObject => {
    return rootObject.Authors.map((author:any) => {
      const row = [
        author.author_name || "",
        author.role|| "",
        rootObject.Project_type || "",
        rootObject.Project_End_Date || "",
        rootObject?.Geography ? rootObject?.Geography.join(', ') : ""
      ];

      row.forEach((value, index) => {
        if (value.length > columnLengths[index]) {
          columnLengths[index] = value.length;
        }
      });

      return row;
    });
  });

  const formatRow = (row: string[]) => {
    return '| ' + row.map((value, index) => value.padEnd(columnLengths[index], ' ')).join(' | ') + ' |';
  };

  const separatorRow = '| ' + columnLengths.map(length => '-'.repeat(length)).join(' | ') + ' |';

  const headerRow = formatRow(headers);
  const formattedRows = rows.map(formatRow);

  const table = [separatorRow, headerRow, separatorRow, ...formattedRows, separatorRow].join('\n');
  return table;
};

export const includeParentsIfAllChildrenChecked = (checkedNodes: any, nodes: any) => {
  const updatedChecked = new Set(checkedNodes);

  const areAllChildrenChecked = (node: any, checked: any) => {
    return node.children.every((child: any) => checked.has(child.value));
  };

  const checkParents = (node: any, parents: any) => {
    if (checkedNodes.includes(node.value)) {
      let allChildrenChecked = true;
      for (let i = parents.length - 1; i >= 0; i--) {
        const parent = parents[i];
        if (allChildrenChecked && areAllChildrenChecked(parent, updatedChecked)) {
          updatedChecked.add(parent.value);
        } else {
          allChildrenChecked = false;
        }
      }
    }

    if (node.children) {
      node.children.forEach((child: any) => {
        checkParents(child, [...parents, node]);
      });
    }
  };

  nodes.forEach((node: any) => {
    checkParents(node, []);
  });

  return Array.from(updatedChecked);
};  

export const encryptEmail =(email: string)=> {
  return CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
}